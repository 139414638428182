import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Gallery from "./gallery"
import Skus from './skus'
import Add from './add'


export default (props) => {

  const data = useStaticQuery(graphql`
      query {
          allContentfulProduct {
              edges {
                node {
                  id
                  inStock
                  priceInCents
                  name
                  weightInGrams
                  description {
                    description
                  }
                  photos {
                    id
                    description
                    localFile {
                      url
                      childImageSharp {
                        fluid(maxWidth: 1024) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                   stripeSku
                  id
                  priceInCents
                }
              }
            }

          }
      `)


  const items = data.allContentfulProduct.edges
  const currentItem = items.filter(({ node: item }) => { return item.id === props.pageContext.itemId });
  const title = currentItem && currentItem.length > 0 ? currentItem[0].node.name : ''
  const description = currentItem && currentItem.length > 0 ? currentItem[0].node.description.description : ''

  currentItem[0].node['type'] = 'product'
  const schemaProduct = currentItem[0].node

  return (
    <Layout>
      <SEO schemaData={schemaProduct} title={title} description={description} />

      <div className="flex bg-white mb-8">
        <div className="container mx-auto flex flex-col lg:flex-row  py-6 lg:py-0 px-4 lg:px-0">
          {currentItem.map(({ node: item }, index) => (
            <div key={item.id} className="w-full lg:w-1/2">
              <div className="block lg:hidden ">
                <div className="flex flex-col lg:flex-row">
                  <div className="flex flex-row">
                    {/*<div className="flex items-center text-sm bg-green-200 text-green-800 px-2 rounded mr-2">*/}
                    {/*  <svg className="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24">*/}
                    {/*    <path d="M3,13.5L2.25,12H7.5L6.9,10.5H2L1.25,9H9.05L8.45,7.5H1.11L0.25,6H4A2,2 0 0,1 6,4H18V8H21L24,12V17H22A3,3 0 0,1 19,20A3,3 0 0,1 16,17H12A3,3 0 0,1 9,20A3,3 0 0,1 6,17H4V13.5H3M19,18.5A1.5,1.5 0 0,0 20.5,17A1.5,1.5 0 0,0 19,15.5A1.5,1.5 0 0,0 17.5,17A1.5,1.5 0 0,0 19,18.5M20.5,9.5H18V12H22.46L20.5,9.5M9,18.5A1.5,1.5 0 0,0 10.5,17A1.5,1.5 0 0,0 9,15.5A1.5,1.5 0 0,0 7.5,17A1.5,1.5 0 0,0 9,18.5Z" />*/}
                    {/*  </svg>*/}
                    {/*  <p className="text-xs text-green-800 font-bold">Free Shipping</p>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <h1 className="font-bold text-lg lg:text-2xl">{item.name}</h1>
                <div className="max-w-sm mt-2 mb-2 lg:mt-4 lg:mb-6" method="post" action="">
                  <div className="bg-gray-200 inline-block rounded  flex items-center justify-between">
                    <p className="font-bold text-center block w-64 text-sm">{(item.priceInCents / 100).toFixed(0)}Lek</p>
                    <Add item={item} />
                  </div>
                </div>
              </div>
              <div  className="w:full md:mx-auto">
                <Gallery photos={item.photos} />
              </div>
            </div>
          ))}
          <div className="w-full lg:w-1/2 px-6">
            {currentItem.map(({ node: item }) => (
              <div key={item.id}>
                <div className="hidden lg:block">
                  <div className="flex">
                    {/*<div className="flex items-center text-sm bg-green-200 text-green-800 px-2 rounded mr-2">*/}
                    {/*  <svg className="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24">*/}
                    {/*    <path d="M3,13.5L2.25,12H7.5L6.9,10.5H2L1.25,9H9.05L8.45,7.5H1.11L0.25,6H4A2,2 0 0,1 6,4H18V8H21L24,12V17H22A3,3 0 0,1 19,20A3,3 0 0,1 16,17H12A3,3 0 0,1 9,20A3,3 0 0,1 6,17H4V13.5H3M19,18.5A1.5,1.5 0 0,0 20.5,17A1.5,1.5 0 0,0 19,15.5A1.5,1.5 0 0,0 17.5,17A1.5,1.5 0 0,0 19,18.5M20.5,9.5H18V12H22.46L20.5,9.5M9,18.5A1.5,1.5 0 0,0 10.5,17A1.5,1.5 0 0,0 9,15.5A1.5,1.5 0 0,0 7.5,17A1.5,1.5 0 0,0 9,18.5Z" />*/}
                    {/*  </svg>*/}
                    {/*  <p className="text-xs text-green-800 font-bold">Free Shipping</p>*/}
                    {/*</div>*/}
                  </div>
                  <h1 className="font-bold text-lg lg:text-2xl">{item.name}</h1>
                  <div className="max-w-sm mt-2 mb-2 lg:mt-4 lg:mb-6">
                    <div className="bg-gray-200 inline-block rounded  flex items-center justify-between">
                      <p className="font-bold text-center block w-64 text-sm">{(item.priceInCents / 100).toFixed(0)}Lek</p>
                      <Add item={item} />
                    </div>
                  </div>
                </div>
                <p className="mb-4">{item.name}</p>
                <p className="mb-4">{item.description.description !== null ? item.description.description : 'This is the description for: ' + item.name}</p>
                <p className="font-bold text-gray-800 mb-2">For more info contact us at: orders@caffevirginia.com</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex items-center ">
          <div className="-ml-16 mr-4 ">
            <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M16.358 16.0681C-9.92792 15.3039 3.08112 -5.94085 15.5656 17.3643C3.08112 -5.94085 27.853 -5.22158 14.0516 17.3194C27.853 -5.22158 39.6195 16.7424 13.3336 15.9782C39.6195 16.7424 26.6105 37.9872 14.126 14.682C26.6105 37.9872 1.83862 37.2679 15.6363 14.727C1.83862 37.2717 -9.92792 15.3039 16.358 16.0681Z" fill="#691C32"/>
            </svg>
          </div>
          <p className="font-bold text-gray-700">Artikuj tjere me interes</p>
        </div>
        <div className="flex flex-wrap py-6">
          <Skus size="sm" exclude={currentItem[0].node.id} />
        </div>

      </div>
    </Layout>
  )
}

