import React, { useState } from "react"
import Img from "gatsby-image"

export default (props) => {
    const photos = props.photos;
    const [activeImage, setActiveImage] = useState(0);

    return (
        <div className="w:full md:mx-auto flex flex-col-reverse lg:flex-row">

            <div className="flex flex-row lg:flex-col">
                {photos.map((image, index) => (
                    <div key={image.id} onClick={() => setActiveImage(index)}
                        className={
                            activeImage !== index
                                ? "cursor-pointer mr-2 w-20 opacity-50 hover:opacity-100 border border-gray-400 rounded m-1"
                                : "cursor-pointer mr-2 w-20 opacity-100 hover:opacity-50 border border-gray-400 rounded m-1"
                        }>
                        <Img className="rounded" fluid={image.localFile.childImageSharp.fluid} />
                    </div>
                ))}
            </div>
            <Img className="mr-2 w-full" fluid={photos[activeImage].localFile.childImageSharp.fluid} />

        </div>
    )
}
