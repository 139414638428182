import React, { useContext, useState } from 'react'
import CartContext from '../context/CartContext'


export default (props) => {
	// const {addedBefore, addedNow, setAddedBefore, setAddedNow} = useState(false, false);
	// const {addItem, getTotalValue, clearCart} = useContext(CartContext)

	const { addItem } = useContext(CartContext)
	const [quantity, setQuantity] = useState(1);


	if (props.item.inStock) {
		 return(
			<>
				<label>
					<input className="px-2" type="number" name="qty" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
				</label>
				<button onClick={() => addItem(props.item, quantity)} className="block w-2/3 cursor-pointer bg-brand-700 hover:shadow active:bg-brand-800 px-6 py-1 font-bold text-white text-sm rounded">
					Add to cart
      </button>
			</>
		)
	} else {
		return (
				<div className=" text-center block w-2/3 bg-brand-700 hover:shadow active:bg-brand-800 px-6 py-1 font-bold text-white text-sm rounded">
					Out of stock, check back soon
      </div>
		)
	}

}